@charset "utf-8";
@import url("https://fonts.googleapis.com/css?family=Noto+Serif+SC:200,300,400,500,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+SC:200,300,400,500,600,700,900");
@import url("https://cdn.bootcdn.net/ajax/libs/Swiper/6.8.0/swiper-bundle.min.css");

.font-serif {
  font-family: "Noto Serif SC", Serif;
}

.font-sans {
  font-family: "Noto Sans SC", Sans;
}

.info div {
  line-height: 1.5;
  padding-bottom: 10px;
}

.info img {
  padding-bottom: 3px;
  padding-right: 3px;
}

.banner-swiper h2 {
  font-family: "Noto Serif SC", Serif;
  letter-spacing: 0.175rem;
}

.banner-swiper .swiper-pagination .swiper-pagination-bullet {
  background: #000000;
  width: 2rem;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  border-radius: unset;
}

.banner-swiper .swiper-pagination .swiper-pagination-bullet-active {
  background: #fff;
}

.banner-swiper .swiper-wrapper {
  height: 50vh;
}
.banner-swiper .swiper-wrapper .swiper-slide {
  height: 100%;
  position: relative;
}
.banner-swiper .swiper-wrapper .swiper-slide .slide-img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 4;
  top: 0;
  opacity: 0.6;
}
.banner-swiper .swiper-wrapper .swiper-slide .slide-img img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.banner-swiper .swiper-wrapper .swiper-slide .silde-info {
  position: relative;
  height: 100%;
  z-index: 10;
}

.message-swiper .swiper-slide .testimonial-thumb {
  width: 300px;
  height: 300px;
  margin: auto;
  border-radius: 50%;
}
.message-swiper .swiper-slide .testimonial-thumb img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}

footer .footer-logo p {
  font-family: "Noto Serif SC", Serif;
  letter-spacing: 5px;
}

footer .footer-nav {
  margin: 10px 0;
}
footer .footer-nav a,
footer .footer-nav li {
  font-size: 0.875rem !important;
  font-weight: 300;
  line-height: 1.6;
}
footer .footer-nav li {
  padding: 0 5px;
}
footer .footer-nav .sm-icon {
  width: 1rem;
  margin-right: 3px;
  margin-bottom: 3px;
}

.home .footer {
  position: absolute;
  bottom: 0;
  margin: auto;
  width: 100%;
  z-index: 99;
}

.home .banner-swiper .swiper-wrapper {
  height: 100vh;
}

.article-info ul li,
.club-info ul li {
  padding: 0 5px;
}
.article-info ul li i,
.club-info ul li i {
  padding-right: 3px;
  font-size: 0.875rem;
}

.club-header .club-info {
  z-index: 10;
  position: relative;
}
.club-header .club-info h1 {
  font-family: "Noto Serif SC", Serif;
  letter-spacing: 3px;
}
.club-header .club-info .fa,
.club-header .club-info .far,
.club-header .club-info .fas,
.club-header .club-info .fab {
  padding-right: 5px;
}

.club-header .bg {
  background: url("//static.jcz.fun/company/static/index/img/bg-img/splay.jpg");
  opacity: 0.6;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.info-table {
  zoom: 1;
  overflow: hidden;
  /* Safari */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.info-table dl {
  display: flex;
  text-align: center;
  width: 50%;
}
.info-table dl dd,
.info-table dl dt {
  float: left;
  margin: 0 10px;
  border-bottom: #ccc 1px dashed;
}
.info-table dl dt {
  width: 160px;
}
.info-table dl dd {
  flex: 1;
}

@media (min-width: 1320px) {
  .max-page .container-xxl {
    width: 1620px;
    max-width: 100%;
  }
}

// footer
footer {
  .footer-links-inline {
    li {
      margin: 0 3px;
      a {
        font-size: 14px !important;
      }
    }
  }
}
// companyInfo
.companyInfo {
  table {
    th {
      text-align: center;
    }
  }
}
// article-page
.article-page {
  .article-title {
    text-align: center;
    font-size: 2.5rem;
    font-weight: normal;
  }
  .article-info {
    text-align: center;
    ul {
      margin: 0 2rem;
      margin-top: 0.625rem;
      li {
        display: inline-block;
      }
    }
  }
  .article-content-area {
    font-weight: lighter;
  }
  .card {
    .card-title {
      min-height: normal;
    }
    img {
      height: 225px;
      object-fit: cover;
    }
  }
}

.map {
  filter: grayscale(61%);
}

.base-page {
  .contact-area {
    .card-body {
      p {
        margin-bottom: 0;
      }
    }
  }
}

[class^="line-"],
[class*=" line-"] {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.line-3 {
  -webkit-line-clamp: 3;
  min-height: 3 * 1.6em;
}

.line-2 {
  -webkit-line-clamp: 2;
  min-height: 2 * 1.6em;
}

.line-1 {
  -webkit-line-clamp: 1;
  min-height: 1 * 1.6em;
}

.footer-logo-text {
  letter-spacing: 5px;
}
