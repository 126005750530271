/*

=========================================================
* Pixel Free Bootstrap 5 UI Kit
=========================================================

* Product Page: https://themesberg.com/product/ui-kit/pixel-free-bootstrap-5-ui-kit
* Copyright 2021 Themesberg (https://www.themesberg.com)

* Coded by https://themesberg.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. Contact us if you want to remove it.

*/

@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700&display=swap");

// update variables here
@import "custom/variables";

@import "../../node_modules/bootstrap/scss/functions";
@import "pixel/variables";

// Third party
@import "../../node_modules/vanillajs-datepicker/sass/datepicker.scss";

// Bootstrap
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";

// Bootstrap Layout & components
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/accordion";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/spinners";

// Helpers
@import "../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack

// Mixins & functions
@import "pixel/mixins";
@import "pixel/functions";

// Utilities
@import "pixel/utilities";

// Layout
@import "pixel/layout";

// Components
@import "pixel/components";

// write your custom styles here!

@import "style.scss";

.ipr-beian {
  background: #ebebeb;
  a {
    height: 200px;
    display: block;
    background-image: url(/assets/img/ipr/ipr-beian.png);
    background-position: 50% 50%;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  @media screen and (max-width: 800px) {
    a {
      background-image: url(/assets/img/ipr/ipr-beian-sm.png);
    }
  }
}
.swiper {
  width: 800px;
  height: 100%;
  margin: auto;
  position: relative;
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    padding: 0 35px;
    // background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-pagination-bullet-active {
    // background: var(--swiper-pagination-color, var(--bs-primary));
    background: #ffffff;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    // color: var(--swiper-pagination-color, var(--bs-primary));
    color: #ffffff;
  }
}
.index-message {
  .swiper {
    width: 100%;
    margin: 10% 0;
    .swiper-pagination {
      bottom: -30px;
    }
    .swiper-pagination-bullet-active {
      background: var(--swiper-pagination-color, var(--bs-primary));
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
      color: var(--swiper-pagination-color, var(--bs-primary));
    }
  }
  .index-message-img {
    // height: 260px;
    // width: 260px;
    // margin: auto;
    img {
      border-radius: 50%;
      width: 100%;
    }
  }
}
